<template>
  <!--begin::Post-->
  <div class="post" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <BaseTable
            :columns="columns"
            :service="'serial'"
            ref="table"
            @actionButton="handleAction"
            @selectedRows="handleSelected"
          >
            <template #header-right>
              <div>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="$router.push({ name: 'serial-create' })"
                >
                  Create
                </button>
                &nbsp;
                <button
                  type="button"
                  class="btn btn-success"
                  @click="$router.push({ name: 'serial-upload' })"
                >
                  Import
                </button>
              </div>
            </template>

            <template #action-button="{ data }">
              <div v-if="data.value.users">
                <div v-for="(v, k) in data.value.users" :key="k">
                  <button
                    class="btn btn-sm btn-secondary"
                    @click="confirmRemove(data.value, v)"
                  >
                    {{ v.username }}
                  </button>
                </div>
              </div>
            </template>
          </BaseTable>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import { toRaw } from "vue";
import Service from "../../../services/base.service";
import BaseTable from "/src/components/BaseTable.vue";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        // { field: "id", title: "ID", isUnique: true },
        { field: "serial_number", title: "Serial NUmber" },
        { field: "status", title: "Status" },
        { field: "note", title: "Keterangan" },
        { field: "actions", title: "Action" },
      ],
    };
  },
  mounted() {
    // this.getListData();
  },
  methods: {
    handleAction(value) {
      switch (value.type) {
        case "create":
          this.$router.push({ name: "serial-create" });
          break;
        case "view":
          this.$router.push({
            name: "serial-update",
            params: { id: value.data.id },
          });
          break;
        case "delete":
          this.deleteConfim(value.data.id);
          break;
        default:
          break;
      }
      console.log("action", value);
    },
    deleteConfim(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteAction(id);
          }
        });
    },
    async deleteAction(id) {
      try {
        const BaseService = new Service("serial");
        await BaseService.deleteData(id);
        this.$refs.table.reload();
      } catch (error) {
        console.log(error);
      }
    },
    handleSelected(value) {
      console.log("selected", value);
    },
    confirmRemove(satker, user) {
      const satkerSelected = toRaw(satker);
      const userSelected = toRaw(user);

      this.$swal
        .fire({
          title: "Konfirmasi!",
          text: `Hapus ${userSelected.username} dari pic satker ${satkerSelected.namasingkat}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.doAction([userSelected.id], [satkerSelected.id]);
          }
        });
    },
    async doAction(userSelected, satkerSelected) {
      const service = new Service("user/satker");
      const result = await service.createData({
        user_ids: userSelected,
        satker_ids: satkerSelected,
        unset: 1,
      });
      this.$refs.table.reload();
      return result;
    },
  },
};
</script>
